<template>
	 <div class="block sm:flex gap-0 sm:gap-10 mt-14">
		<div class="w-full sm:w-1/3 text-left">
		</div>
		<div class="w-full sm:w-2/3 text-left mt-10 sm:mt-0">
		<p
				class="text-primary-dark dark:text-primary-light text-2xl font-bold mb-7"
			>
				{{datearticol[0].titlu}}
		</p>
		<p class="text-primary-dark dark:text-primary-light text-2xl font-bold mb-7" v-html="datearticol[0].continut">	
		</p>
		<!--de aici incepe : 
		 <br> datearticol1: {{datearticol}} <br> -->
		<!-- <br> datearticol: {{datearticol[0]}} <br> -->
		<ul>
    		<li v-for="(item, key, index) in datearticol[0]" :key="index">
      		<!-- item {{ item }} - key {{ key }} - index {{ index }} -->
			 
					<div v-show="[8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139,140,141,142,143,144
].includes(index) && item !== ''">
						Fisier atașat: {{ item }} {{ item }}
					<a
					:href="item"
					class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-xl bg-indigo-50 hover:bg-indigo-500 text-gray-500 hover:text-white"
					target="_blank"
					>
					<i
						data-feather="arrow-down-circle"
						class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6"
					></i>
					<span v-show="item"> Descarca fisier atasat</span>
					<!-- <span class="text-sm sm:text-lg" v-if="datearticol[0].attachment != null"> Descarca  {{ item.split('/')[5].substring(0,17) }}...</span> -->
					</a>
					</div>
					 <br>
    		</li>
  		</ul>

		<!--  
			<ul v-for="(item, nr) in datearticol[0]" :key="nr">
			item - nr: {{item - nr}}
			<li>
				
			</li>
		</ul>
		<br>	
		<div v-show="datearticol[0].attachment">
			Fisier atașat: {{datearticol[0].attachment.split('/')[4]}}
				<a
					:href="datearticol[0].attachment"
					class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-xl bg-indigo-50 hover:bg-indigo-500 text-gray-500 hover:text-white"
					target="_blank"
				>
					<i
						data-feather="arrow-down-circle"
						class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6"
					></i>
					<span class="text-sm sm:text-lg" v-if="datearticol[0].attachment != null"> Descarca  {{datearticol[0].attachment.split('/')[4].substring(0,17)}}...</span>
				</a>
		</div>
			articol 2		
				<br>
				<div v-show="datearticol[0].attachment2">
					Fisier atașat: {{datearticol[0].attachment2.split('/')[4]}}
				<a  
					:href="datearticol[0].attachment2"
					class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-xl bg-indigo-50 hover:bg-indigo-500 text-gray-500 hover:text-white"
					target="_blank"
					v-if="datearticol[0].attachment2"
				>
					<i
						data-feather="arrow-down-circle"
						class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6"
					></i>
					<span class="text-sm sm:text-lg" v-if="datearticol[0].attachment2 != null">Descarca {{datearticol[0].attachment2.split('/')[4].substring(0,17)}}...</span>
				</a>
				</div>
				<br>	
				<div v-show="datearticol[0].attachment3">
				<br>
				Fisier atașat: {{datearticol[0].attachment3.split('/')[4]}}
				<a
					:href="datearticol[0].attachment3"
					class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-xl bg-indigo-50 hover:bg-indigo-500 text-gray-500 hover:text-white"
					target="_blank"
					v-if="datearticol[0].attachment3"
				>				
					<i
						data-feather="arrow-down-circle"
						class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6"
					></i>
					<span class="text-sm sm:text-lg" v-if="datearticol[0].attachment3 != null">Descarca {{datearticol[0].attachment3.split('/')[4].substring(0,17)}}...</span>
				</a>
				</div>
				<br>	<div v-show="datearticol[0].attachment4">
					Fisier atașat: {{datearticol[0].attachment4.split('/')[4]}}
				<a
					:href="datearticol[0].attachment4"
					class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-xl bg-indigo-50 hover:bg-indigo-500 text-gray-500 hover:text-white"
					target="_blank"
					v-if="datearticol[0].attachment4"
				>
					<i
						data-feather="arrow-down-circle"
						class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6"
					></i>
					<span class="text-sm sm:text-lg" v-if="datearticol[0].attachment4 != null">Descarca {{datearticol[0].attachment4.split('/')[4].substring(0,17)}}...</span>
				</a>
				</div> -->
		<br>
		<p class="text-primary-dark dark:text-primary-light text-2xl font-bold mb-7">
			Data: {{datearticol[0].data}}
		</p>
		</div>
	</div>
</template>

<script>
import decljson from '../../components/baza/json/declaratiiaveresiinterese.json'

export default {
	// setup() {
	// },
	data() {
		return {
			articole: decljson.reverse(),	
			}
		},
		computed: {
			/* eslint-disable no-mixed-spaces-and-tabs */
      		datearticol() {
				/* eslint-disable no-mixed-spaces-and-tabs */
				//console.log('manjson:',manjson)
				/* eslint-disable no-mixed-spaces-and-tabs */
        		return decljson.filter( manual => manual.nr == this.$route.params.nr)
				//console.log('manjson:',manjson)
      		}
    }
	// 	mounted() {
	// 	const data = manjson.filter( manual => manual.nr == this.$route.params.nr)
	// 	//props.articole.trim()
	// },
};
</script>
