<template>
	<div class="container mx-auto sm:flex py-5 sm:py-10 mt-10 sm:mt-20">
		<div class="w-full sm:w-1/2">
			<div class="leading-loose">
				<form
					class="max-w-xl m-4 p-6 sm:p-10 bg-secondary-light dark:bg-secondary-dark rounded-xl shadow-xl text-left"
				>
					<p
						class="text-primary-dark dark:text-primary-light text-2xl font-semibold mb-8"
					>
						Formă de contact
					</p>
					<div class="">
						<label
							class="block text-lg text-primary-dark dark:text-primary-light mb-2"
							for="name"
							>Nume si prenume</label
						>
						<input
							class="w-full px-5 py-2 border-0 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md dark:font-medium"
							id="name"
							name="name"
							v-model="numesiprenume"
							type="text"
							required=""
							placeholder="Nume si prenume - minim 7 caractere"
							aria-label="Name"
							minlength="7"
						/>
					</div>
					<div class="mt-6">
						<label
							class="block text-lg text-primary-dark dark:text-primary-light mb-2"
							for="email"
							>Email</label
						>
						<input
							class="w-full px-5 py-2 border-0 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md dark:font-medium"
							id="email"
							name="email"
							v-model="email"
							type="text"
							required=""
							placeholder="Email-ul tau"
							aria-label="Email"
						/>
						<div v-if="reg.test(email.toLowerCase()) !== true && email.length"
              				show
              				variant="warning" role="alert">
							<div class="bg-red-500 text-white font-bold rounded-t px-4 py-2">
								Atentie!!!
							</div>
  							<div class="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
              					<p>Email-incorect!!</p>
						</div>
						</div>
					</div>
					<div class="mt-6">
						<label
							class="block text-lg text-primary-dark dark:text-primary-light mb-2"
							for="subject"
							>Subiect</label
						>
						<input
							class="w-full px-5 py-2 border-0 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md dark:font-medium"
							id="subject"
							name="subject"
							v-model="subject"
							type="text"
							required=""
							placeholder="Subiect - minim 5 caractere"
							aria-label="Subject"
						/>
					</div>

					<div class="mt-6">
						<label
							class="block text-lg text-primary-dark dark:text-primary-light mb-2"
							for="message"
							>Mesajul dumneavoastra</label
						>
						<textarea
							class="w-full px-5 py-2 border-0 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md dark:font-medium"
							id="message"
							name="message"
							minlength="32"
							v-model="message"
							cols="14"
							rows="6"
							required=""
							placeholder="minim 32 de caractere"
						></textarea>
					</div>
					<div class="w-full px-5 py-2 border-0 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md dark:font-medium">
              			<label>Codul de siguranta:
                		<br />
                		<h5><strong>{{ codsig }}</strong></h5>
              			</label>
              			<br />
              			<p>Introdu codul de siguranta pentru a trimite mesajul!</p>
              		<input
            			type="number"
                		class="form-control"
                		placeholder="Introduce codul AICI"
                		autocomplete="codsigverif"
                		v-model="codsigverif"
              		/>
            		</div>
					 <input type="hidden" name="_csrf" v-model="csrfToken">
					<div v-show="codsig == codsigverif" class="mt-6">
						<button
							class="px-4 py-2.5 text-white font-medium tracking-wider bg-indigo-500 hover:bg-indigo-600 rounded-lg"
							type="submit"
							@click.prevent="trimitemesaj"
						>
							Trimite Mesaj
						</button>
					</div>
					<input  type="hidden" name="_csrf" v-bind:value="csrfToken">
					<!-- token: {{csrfToken}}
					<div v-show="codsig == codsigverif" class="mt-6">
						<button
							class="px-4 py-2.5 text-white font-medium tracking-wider bg-indigo-500 hover:bg-indigo-600 rounded-lg"
							type="submit"
							@click.prevent="testcsrf"
						>
							Test csrf
						</button>
					</div> -->
				</form>
			</div>
		</div>
		<div class="w-full sm:w-1/2">
			<div class="text-left max-w-xl px-6">
				<h2
					class="text-2xl text-primary-dark dark:text-primary-light font-semibold mt-12 mb-8"
				>
					Detalii de contact:
				</h2>
				<ul class="">
					<li class="flex">
						<i
							data-feather="map-pin"
							class="w-5 text-gray-500 dark:text-gray-400 mr-4"
						></i>
						<p
							class="text-lg mb-4 text-ternary-dark dark:text-ternary-light"
						>
							Str. General Berthelot nr. 26, Sector 1, 010168, Bucureşti
						</p>
					</li>
					<li class="flex">
						<i
							data-feather="mail"
							class="w-5 text-gray-500 dark:text-gray-400 mr-4"
						></i>
						<p
							class="text-lg mb-4 text-ternary-dark dark:text-ternary-light"
						>
							office@rocnee.eu
						</p>
					</li>
					<li class="flex">
						<i
							data-feather="phone"
							class="w-5 text-gray-500 dark:text-gray-400 mr-4"
						></i>
						<p
							class="text-lg mb-4 text-ternary-dark dark:text-ternary-light"
						>
							Tel: +40.21.314.44.11, Fax: +40.21.310.32.07
						</p>
					</li>

					<li> 
						<br/>
					</li>

					<li class="flex">
						<i
							data-feather="map-pin"
							class="w-5 text-gray-500 dark:text-gray-400 mr-4"
						></i>
						<div class="mapouter"><div class="gmap_canvas"><iframe class="gmap_iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=474&amp;height=262&amp;hl=en&amp;q=Str. General Berthelot, nr. 26, Bucharest&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
						</iframe></div></div>
					</li>
					
					<li> 
						<br/>
					</li>
					
					<li class="flex">
						<i
							data-feather="map-pin"
							class="w-5 text-gray-500 dark:text-gray-400 mr-4"
						></i>
						<p
							class="text-lg mb-4 text-ternary-dark dark:text-ternary-light"
						>
							Directii de orientare:
						</p>
						<div>
							<a href="https://www.google.com/maps/dir//Strada+General+H.+M.+Berthelot+26,+Bucure%C8%99ti+010164,+Romania/@44.441842,26.0871683,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x40b1ff5a7d97c4ff:0x159af629dd24d226!2m2!1d26.089357!2d44.441842?hl=en"><h3>Click aici</h3></a>
						</div>
					</li>

					<li> 
						<br/>
					</li>

					<li> 
						<div>
							<p
							class="text-lg mb-4 text-ternary-dark dark:text-ternary-light"
						>Director General: Marian ȘUȚĂ: </p>
						<p
							class="text-lg mb-4 text-ternary-dark dark:text-ternary-light"
						>E-mail: - <a href="mailto:{{member[0].email}}">{{member[0].name}}</a> -  marian.suta@rocnee.eu</p>
				<a
					href="/pdf/cvsuta.pdf"
					class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-xl bg-indigo-50 hover:bg-indigo-500 text-gray-500 hover:text-white"
					target="_blank"
				>
				<!-- <link rel="icon" href="<%= BASE_URL %>cv.pdf"> -->
					<i
						data-feather="arrow-down-circle"
						class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6"
					></i>
					
					<span class="text-sm sm:text-lg">Descarca CV</span>
				</a>
				</div>
					</li>

					<li> 
						<br/>
					</li>

					<li> 
						<br/>
					</li>


				<li> 
						<div>
							<p
							class="text-lg mb-4 text-ternary-dark dark:text-ternary-light"
						>Director General Adjunct: Cristina BOȚAN - </p>
						<p
							class="text-lg mb-4 text-ternary-dark dark:text-ternary-light"
						>E-mail: - <a href="mailto:{{member[1].email}}">{{member[1].name}}</a> - cristina.botan@rocnee.eu</p>
				<a
					href="/pdf/cvbotan.pdf"
					class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-xl bg-indigo-50 hover:bg-indigo-500 text-gray-500 hover:text-white"
					target="_blank"
				>
				<!-- <link rel="icon" href="<%= BASE_URL %>cv.pdf"> -->
					<i
						data-feather="arrow-down-circle"
						class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6"
					></i>
					
					<span class="text-sm sm:text-lg">Descarca CV</span>
				</a>
				</div>
					</li>


				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import feather from 'feather-icons';
import axios from "axios";
import Swal from "sweetalert2";

export default {
	name: "Contact",
  props: {
    csrfname: {
      type: String,
        default: null
    } 
  },
	data: () => {
		return {
			csrfToken: null,
			member:[
				{name: "Marian Suta", email: "marian.suta@rocnee.eu"},
				{name: "Cristina Botan", email: "marian.suta@rocnee.eu"},
			],
        	subject: "",
        	email: "",
        	message: "",
        	tokenapimail: "",
        	checked: false,
        	ExtIp: undefined,
			numesiprenume: '',
			codsigverif: 0,
			codsig: 0,
			reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		};
	},

	beforeMount() {
		//this.getcsrf()
		//this.getcsrfToken(),
		this.GetIp()
	},

	mounted() {
		feather.replace();
		
	},
	updated() {
		feather.replace();
	},
	created: function() {
    	this.getRandomNumber();
  	},
	methods: {
		  
     		//fetch ip external
			GetIp() { 
  					try {
    					fetch("https://api.ip2loc.com/5usYizyno6hNEUP2PnIRmyf5rLgfXDdw/detect")
    					.then(response => response.json())
    					.then(response => {
      					if (response.success) {
        					if (response.location.country.eu_member) {
          						//showGdpr();
          						console.log("Ok: GDPR")
        						}
        					console.log("external IP: ", response.connection.ip);
        					this.ExtIp = response.connection.ip
        
     						 }
    					})
  					}
    				catch(error) {
      					if(error) console.error('error: ',error)
      						this.ExtIp = undefined
    					}
  					},
  					//end fetch ip ext
		getcsrfToken() {
			axios.create({withCredentials: true}).get('http://localhost:4331/getcsrf')
      			.then(res => {
        			const data = res.data
        			this.csrfToken = data.csrfToken
        			axios.defaults.headers.common['XSRF-TOKEN'] = this.csrfToken
      			})
      			.catch(error => console.log(error))
		},

		getRandomNumber: function() {
      		this.codsig = this.generateNumber();
    	},
    	generateNumber: function() {
      		return Math.floor(Math.random() * (999999999 - 10000000 + 1) + 10000000);
    	},
		async trimitemesaj() {
			console.log("date de trimis: ",this.numesiprenume + ' ' + this.email + ' ' + this.subject + ' ' + this.message)
			console.log('lengths1: ',this.subject.length)
			console.log('lengths2: ',this.numesiprenume.length )
			console.log('lengths3: ',this.message.length)
			if(!this.numesiprenume || !this.email || !this.subject || !this.message) {
				alert('Trebuie sa completati toate campurile!');
				return 1
			}
			if(this.numesiprenume.length < 7 || this.subject.length < 5 || this.message.length < 32) {
				alert('Trebuie sa respectati cerintele minime de completare trecute in fiecare camp!!!');
				return 2
			}
			//let email = this.email;
      		//const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      		// if (this.email == null || this.email == "") {
        	// 		let errorEmail = "Introduceti email-ul!";
			// 		alert('error: ',errorEmail)
      		// 	} else if (!this.reg.test(this.email)) {
        	// 	let errorEmail = "Introduceti email-ul corect!";
			// 	alert('error: ',errorEmail)
      		// }
			// await axios.create({withCredentials: true,  headers: {Accept: "application/json", "Content-Type": "application/json"}}).post("http://localhost:4331	/sendmail", 
			//  	{
			// 	csrfToken: this.csrfToken,
			// 	tokenapimail: "ke3-3=-53teujkgfs=2=gdsg,g,hkndsjdj2ADSAGLF =!!@e<f<}t^tyuh<fbnf:b", 
			//  	subject: this.subject, 
			//  	email: this.email,
			//  	message: this.message,
			//  	ExtIp: "localhost",
			// 	numesiprenume: this.numesiprenume
			//  })
        	// .then(response => {
          	// 	let info = response;
          	// 	console.log("response: ", info);
			// 	console.log("response: ", info.data);
			// 	console.log("response: ", info.data.response);
          	// 	if (info.data.response.includes("250")) {
            // 		//alert("Mesajul a fost trimis!");
			// 		Swal.fire(
            //       'Mesajul a fost trimis!',
            //       'Multumim.',
            //       'success'
            //     )
            // 		//this.cangenpdf = true;
            // 		//this.$router.push("/savepdf/" + this.$route.params.id);
          	// 		} else 
			// {
			// 	//alert("Mesajul nu a fost trimis! S-a ivit o eroare:", response);
			// 	Swal.fire(
            //       'Mesajul NU a fost trimis!',
            //       'Multumim.',
            //       'warning'
            //     )
			// }
        	// 	})
        	// 	.catch(error => {
			// 		Swal.fire(
            //       'Mesajul NU a fost trimis!',
            //       'Multumim.',
            //       'error: '+error
            //     )
			// 		//alert("Eroare: " + error + " !")
			// 	});
			Swal.fire({
          		title: 'Sunteti sigur ca vreti sa trimiteti mesajul: ' + this.message + " ???",
          		text: "Nu veti putea reveni asupra lui!",
          		icon: 'warning',
          		showCancelButton: true,
          		confirmButtonColor: '#3085d6',
          		cancelButtonColor: '#d33',
          		confirmButtonText: 'Da, trimite mesajul!'
          	})
		  	.then((result) => {
				console.log('result: ',result)
			  	console.log('result.value: ',result.value)
				  if(result.value == true) {
					//axios.create({withCredentials: true,  headers: {Accept: "application/json", "Content-Type": "application/json"}}).post("http://localhost:4331/sendmail",
					axios.post("https://protected-tor-17941.herokuapp.com/sendmail",
						{
						csrfToken: this.csrfToken,
			 			tokenapimail: "ke3-3=-53teujkgfs=2=gdsg,g,hkndsjdj2ADSAGLF =!!@e<f<}t^tyuh<fbnf:b", 
						subject: this.subject, 
						email: this.email,
						message: this.message,
						numesiprenume: this.numesiprenume,
					  	ExtIp: this.ExtIp,
						})
                		.then(response => {
          					console.log("response1: ", response);
							 console.log("response2: ", response.data);
							  console.log("response3: ", response.data.code);
							  console.log("response4: ", response.data.status);
          					if (response.status === 200) {
            					Swal.fire(
                  					'Mesajul a fost trimis!',
                  					'Felicitari!',
                  					'success'
                					)
          					} else {
								  Swal.fire(
                  					'Mesajul NU a fost trimis!',
                  					'Reincearca!',
                  					'error'
                					)
							}
						})
				  } else {
					  Swal.fire(
                  		'Mesajul NU a fost trimis!',
                  		'Reincearca!',
                  		'warning'
                		)
						//this.clearForm();
                    this.message = ""
                    this.subject = ""
                    this.email = ""
                    this.ExtIp = ""
                    this.isSending = false;
				  }
			})
		}
}

};
</script>

<style scoped>
.mapouter{position:relative;text-align:right;width:474px;height:262px;}.gmap_canvas {overflow:hidden;background:none!important;width:474px;height:262px;}.gmap_iframe {width:474px!important;height:262px!important;}
</style>