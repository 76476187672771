<template>
	<!-- Related projects start -->
	<div class="mt-10 sm:mt-20">
		<!-- <p
			class="text-primary-dark dark:text-primary-light text-3xl font-bold mb-10 sm:mb-14"
		>
			Articole asemanatoare:
		</p>
		<div class="grid grid-cols-1 sm:grid-cols-4 gap-10"> -->
			<!-- <div class="w-full sm:w-2/3 text-left mt-10 sm:mt-0" v-for="item in articole" :key="item.nr">
			<p
				class="text-primary-dark dark:text-primary-light text-2xl font-bold mb-7"
			>
				Challenge
			</p>
			<p class="mb-5 text-lg text-ternary-dark dark:text-ternary-light">
				{{ item.titlu }}
			</p>
			<p class="mb-5 text-lg text-ternary-dark dark:text-ternary-light">
				{{item.continut}}
			</p>
			 -->
			<!-- <div v-for="item in articole" :key="item.nr">
				<img
					:src="item.to"
					class="rounded-2xl cursor-pointer"
					alt=""
				/>
				<p
				class="text-primary-dark dark:text-primary-light text-2xl font-bold mb-7"
			>
				
			</p>
			<p class="mb-5 text-lg text-ternary-dark dark:text-ternary-light">
				{{ item.titlu }}
			</p>
			<p class="mb-5 text-lg text-ternary-dark dark:text-ternary-light">
				{{item.continut}}
			</p>
			</div> -->
			
			<!-- <div>
				<img
					src="../../assets/images/mobile-project-1.jpg"
					class="rounded-2xl cursor-pointer"
					alt="Related Project"
				/>
			</div>
			<div>
				<img
					src="../../assets/images/web-project-1.jpg"
					class="rounded-2xl cursor-pointer"
					alt="Related Project"
				/>
			</div>
			<div>
				<img
					src="../../assets/images/ui-project-2.jpg"
					class="rounded-2xl cursor-pointer"
					alt="Related Project"
				/>
			</div>
			<div>
				<img
					src="../../assets/images/mobile-project-1.jpg"
					class="rounded-2xl cursor-pointer"
					alt="Related Project"
				/>
			</div> 
		</div> -->
	</div>
	<!-- Related projects end -->
</template>

<script>
import cpeecnjson from '../../components/baza/json/cpeecn.json'
export default {
	setup() {},
	data() {
		return {
			articole: cpeecnjson,	
		}
		
		},
};
</script>
