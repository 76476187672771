<template>
	<div class="container mx-auto">
		<!-- Projects start -->
		<DceeGrid />
		<!-- Projects end -->
	</div>
</template>

<script>
import DceeGrid from '@/components/project/DceeGrid';
import feather from 'feather-icons';

export default {
	name: 'Dcee',
	components: {
		DceeGrid,
	},
	props: {
		msg: String,
	},
	data: () => {
		return {};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<style scoped></style>
