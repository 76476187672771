<template>
	<div>
		<!-- Project heading and meta info start -->
		<p
			class="text-left text-3xl sm:text-4xl font-bold text-primary-dark dark:text-primary-light mt-7 sm:mt-20 mb-7"
		>
			Categorie: Legea 544
		</p>
		<div class="flex">
			<div class="flex items-center mr-10">
				<i
					data-feather="clock"
					class="w-4 h-4 text-ternary-dark dark:text-ternary-light"
				></i>
				<span
					class="ml-2 leading-none text-primary-dark dark:text-primary-light"
					>Azi: {{dateTime}}</span
				>
			</div>
			<div class="flex items-center">
				<i
					data-feather="tag"
					class="w-4 h-4 text-ternary-dark dark:text-ternary-light"
				></i>
				<span
					class="ml-2 leading-none text-primary-dark dark:text-primary-light"
					></span
				>
			</div>
		</div>
		<!-- Project heading and meta info end -->
	</div>
</template>

<script>
export default {
	setup() {},
	data() {
		return {
			dateTime: 0,	
		}
		},
		 methods: {
    		currentDateTime() {
      		const current = new Date();
      		const date = current.getFullYear()+'-'+(current.getMonth()+1)+'-'+current.getDate();
      		const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
      		this.dateTime = date +' '+ time;
			console.log('dateTime:',this.dateTime)
      		return this.dateTime;
    		}
  		},
		mounted() {
		this.currentDateTime();
		//props.articole.trim()
		},
};
</script>
