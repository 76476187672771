<template>

<div class="btn-group">
  <p class="mb-8 text-ternary-dark dark:text-ternary-light text-lg">Link-uri Transparenta institutionala:</p>

<!-- Using utilities: -->
<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" name="anunturi" value="anunturi" @click="gotoPage($event)">
  Pagina Anunturi
</button>
<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" name="concursuri" value="concursuri" @click="gotoPage($event)">
  Pagina Concursuri
</button>
<!-- <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" name="audit" value="audit" @click="gotoPage($event)">
  Pagina Audit
</button> -->
<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" name="scim" value="scim" @click="gotoPage($event)">
  Pagina SCIM
</button>
<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" name="transparenta" value="transparenta" @click="gotoPage($event)">
  Pagina Transparenta
</button>
<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" name="legislatie" value="legislatie" @click="gotoPage($event)">
  Pagina Legislatie
</button>
<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" name="platformeinformatice" value="platformeinformatice" @click="gotoPage($event)">
  Pagina Platforme informatice
</button>
<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" name="rapoarteperiodice" value="rapoarteperiodice" @click="gotoPage($event)">
  Pagina Rapoarte periodice
</button>
<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" name="achizitiisilicitatii" value="achizitiisilicitatii" @click="gotoPage($event)">
  Pagina Achizitii si licitatii
</button>
<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" name="transparentafinanciara" value="transparentafinanciara" @click="gotoPage($event)">
  Pagina Transparenta financiara
</button>
<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" name="venituriangajati" value="venituriangajati" @click="gotoPage($event)">
  Pagina Venituri angajati
</button>
<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" name="declaratiiaveresiinterese" value="declaratiiaveresiinterese" @click="gotoPage($event)">
  Pagina Declaratii de avere si interese
</button>

<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" name="legea544" value="legea544" @click="gotoPage($event)">
  Pagina Legea 544
</button>

<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" name="registruunictransparentaintereselor" value="registruunictransparentaintereselor" @click="gotoPage($event)">
  Pagina Registru unic al transparentei intereselor
</button>

<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" name="programescolare" value="programescolare" @click="gotoPage($event)">
  Pagina Programe scolare
</button>

<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" name="talis" value="talis" @click="gotoPage($event)">
  Pagina TALIS
</button>
  
  </div>


</template>


<script>
export default {
  name: 'Transparenta-institutionala',
  // created() {
  //   window.location.href = "/404/"
  // }
  data() {
		return {
			name: '',
      id: '',
      value: ''
		};
	},

  methods:{
    gotoPage(event){
      console.log(event.target.name);
      // console.log('go To Page value :', this.value);
      // console.log('go To Page id :', $event.target.value);
      this.$router.push({ path: event.target.name});
      //window.location.href = name
    },

    // goToAnunturi(){
    //  console.log('go To Anunturi: /anunturi')
    //  this.$router.push({ path: "anunturi"})
    //  //window.location.href = name
    // },

    //  say: function (msg) {
    //   alert(msg)
    // }

  }
}

</script>

<style scoped>


.button {
  font: bold 23px Arial;
  text-decoration: none;
  background-color: #EEEEEE;
  color: #333333;
  padding: 2px 6px 2px 6px;
  border-top: 1px solid #CCCCCC;
  border-right: 1px solid #333333;
  border-bottom: 1px solid #333333;
  border-left: 1px solid #CCCCCC;
}

.button:hover {background-color: #3e8e41}

.button:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.btn-group button {
  background-color: #2d79d1; /* Green background */
  border: 1px solid rgb(128, 36, 0); /* Green border */
  color: white; /* White text */
  padding: 10px 24px; /* Some padding */
  cursor: pointer; /* Pointer/hand icon */
  width: 100%; /* Set a width if needed */
  display: block; /* Make the buttons appear below each other */
}

.btn-group button:not(:last-child) {
  border-bottom: none; /* Prevent double borders */
}

/* Add a background color on hover */
.btn-group button:hover {
  background-color: #da1546;
}

div.containertransp {
    height: 20em;
    position: relative }
div.containertransp p {
    margin: 0;
    background: none;
    position: absolute;
    top: 20px;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%) }


</style>