<template>
	<div class="container mx-auto">
		<!-- Projects start -->
		<TransparentaGrid />
		<!-- Projects end -->
	</div>
</template>

<script>
import TransparentaGrid from '@/components/project/TransparentaGrid';
import feather from 'feather-icons';

export default {
	name: 'Transparenta',
	components: {
		TransparentaGrid,
	},
	props: {
		msg: String,
	},
	data: () => {
		return {};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<style scoped></style>
