<template>
	<div id="app">
     <div class="folders">
		 <h3>Directoare: (Dati click pe numele directorului)</h3>
          <ul>
               <li v-for="(folder,index) in folders" :key="index">
                    <!--  Director nr. {{index}} {{folder.name}} -->
						<a @click="showFolders(folder.id)">
						<button class="button">Director nr. {{index}} {{folder.name}}</button> 
						</a>
               </li>
			   
          </ul>
     </div>
<!-- (Dati click pe link-urile fisierelor) -->
	 <h3>Subfolderele din folderul: {{subfoldername}}  </h3>
     <div class="files">
          <ul>
               <li v-for="(subfolder,index) in subfolders" :key="index">
                   <!-- {{subfolder.name}} :: == ::  {{subfolder.files}} -->
					<a @click="showFiles(subfolder.index)">
						<button class="button">SubDirector nr. {{index}} {{subfolder.name}}</button> 
						</a>
				<h3>Fisierele din folderul: {{subfolder.name}}  </h3>
				<ul>
					
               	<li v-for="(file,index) in subfolder.files" :key="index">
                    <!-- Nume fisier: {{file}} : Link fisier ->  fisiere: {{file}}
					sub: {{subfoldername}} -->
					<!-- subfolders: {{file}}  -->
					
					<a :href="/foldere/+subfoldername+'/'+subfolder.name+'/'+file" target="_blank">
						<button class="buttonfisier">{{file}}</button> 
					</a> 
               </li>
          </ul>
					<!-- <a :href="/foldere/+foldername+'/'+subfoldername+'/'+file" target="_blank">
						<button class="buttonfisier">{{file}}</button> 
					</a> -->
               </li>
          </ul>
     </div>

	 <!-- <h3>Fisierele din folderul: {{subfoldername}} (Dati click pe link-ul fisierului)</h3>
     <div class="files" v-if="subfolders">
          <ul>
               <li v-for="(file,index) in subfolders" :key="index">
                    
					sub: {{subfoldername}} ::: {{subfoldername}}
					<ul v-if="subfolders.name==subfoldername">
					 <li v-for="(filesub,index) in subfolders" :key="index">
						 {{filesub.files}}
						 <a :href="/foldere/+subfoldername+'/'+file.name+'/'+file.files[0]" target="_blank">
						<button class="buttonfisier">{{file.files[0]}}</button> 
					</a>
						 </li>
					</ul>
					<a :href="/foldere/+subfoldername+'/'+file.name+'/'+file.files" target="_blank">
						<button class="buttonfisier">{{file.files}}</button> 
					</a>
               </li>
          </ul>
     </div> -->


</div>
</template>

<script>
import feather from 'feather-icons';

export default {
	name: 'ArhivaGrid',
	props: {
		msg: String,
	},
	data() {
		return {
			folders : [
        			{
					id: 0, 
					name: 'Planuri_de_invatamant', 
					subfolders: [
						{
							id: 0,
							name: 'Invatamant_prescolar_pi',
							files:['@favicon_pre.png','cv_pre.pdf','arhiva_pre.zip']
						}, 
						{
							id: 1,
							name: 'Invatamant_primar_pi',
							files:['@favicon_pri.png','cv_pri.pdf','arhiva_pri.zip']
						},
						{
							id: 2,
							name: 'Invatamant_gimnazial_pi',
							files:['@favicon_gim.png','cv_gim.pdf','arhiva_gim.zip']
						}, 
						{
							id: 3,
							name: 'Invatamant_liceal_pi',
							files:['@favicon_lic.png','cv_lic.pdf','arhiva_lic.zip']
						}, 
					],
					// files:['@favicon_presc.png','cv_presc.pdf','arhiva_presc.zip']
					},
        			{
					id: 1, 
					name: 'Programe_scolare',
					subfolders: [
						{
							id: 0,
							name: 'Invatamant_prescolar_ps',
							files:['@favicon_pre.png','cv_pre.pdf','arhiva_pre.zip']
						}, 
						{
							id: 1,
							name: 'Invatamant_primar_ps',
							files:['@favicon_pri.png','cv_pri.pdf','arhiva_pri.zip']
						},
						{
							id: 2,
							name: 'Invatamant_gimnazial_ps',
							files:['@favicon_gim.png','cv_gim.pdf','arhiva_gim.zip']
						}, 
						{
							id: 3,
							name: 'Invatamant_liceal_ps',
							files:['@favicon_lic.png','cv_lic.pdf','arhiva_lic.zip']
						}, 
					],
					// files:['@favicon.png2','cv2.pdf','arhiva2.zip']
					},
    			],
			folders2 : [
        			{id: 0, 
					name: 'folder1', 
					files:['@favicon.png','cv.pdf','arhiva.zip']
					},
        			{id: 1, 
					name: 'folder2', 
					files:['@favicon.png2','cv2.pdf','arhiva2.zip']
					},
        			{id: 2, name: 'folder3', files:['@favicon.png3','cv3.pdf','arhiva3.zip']},
    			],
				subfolders: [],
				subfoldername: '',
				files: [],
				subfiles: [],
				foldername: '',
				
				}
		
	},
	methods: {
		/* eslint-disable no-mixed-spaces-and-tabs */
		//
		showFolders(id){
               let f = (this.folders).filter(f=>{return f.id==id})[0];
               this.subfolders = f.subfolders;
			   this.subfoldername = this.folders[id].name
			   console.log('subfoldername: ',this.folders[id].name)
          },
		
		reseteaza() {
			// this.search = null
			// return this.articole.filter(post => {
        	// 	return post.titlu.toLowerCase().includes(this.search.toLowerCase())
				
      		// })	
			//this.$forceUpdate();
			this.$router.go('/')
		},

		showFiles(id){
			console.log('this.folders: ',this.folders)
			console.log('this.folders0: ',this.folders[0].subfolders[0].files)
			console.log('this.folders0: ',this.folders[0].subfolders[1].files)
			console.log('this.folders1: ',this.folders[1].subfolders[0].files)
			console.log('this.folders1: ',this.folders[1].subfolders[1].files)
			console.log('this.folders.subfolders: ',this.folders.subfolders)
			let f = (this.folders).filter(f=>{return f.id==id})[0];
            // this.subfiles = f.subfolders;
			// console.log('this.subfiles: ',this.subfiles)
			// this.foldername = this.folders[id].name
			// console.log('foldername: ',this.folders[id].name)
          }
	},
		//return articole,
	mounted() {
		feather.replace();
	
		//props.articole.trim()
	},
	updated() {
		feather.replace();
		//datearticol() {
				/* eslint-disable no-mixed-spaces-and-tabs */
        		// manjson.sort( function (a, b) {
  				// 	return a.nr - b.nr;
				// });
      	//};
	},
	computed: {
		//manjson.sort((a, b) => a.name - b.name );
		//return manjson;
			// sortedArray() {
			// 	const sortDirection = 'DESC'
			// 	manjson.sort( function( a, b ){
    		// 		if( sortDirection == 'ASC' ){
      		// 			return parseInt( a.cafes_count ) < parseInt( b.cafes_count ) ? 1 : -1;
    		// 		}
    		// 		if( sortDirection == 'DESC' ){
      		// 			return parseInt( a.cafes_count ) > parseInt( b.cafes_count ) ? 1 : -1;
    		// 		}
			// 		return articole = manjson;
			// 	}
			// }
	}
	// computed() {
  	// 	sortedArray() {
    // 		function compare(a, b) {
    //   			if (a.name < b.name)
    //     			return -1;
    //   			if (a.name > b.name)
    //     			return 1;
    //   			return 0;	
    // 			}
    // 		return this.arrays.sort(compare);
  	// 	}
	// };
};
</script>

<style scoped>

.folders {
    float: left;
    width: 50%;
}

.files {
    margin-left: 50%;
}

.button {
  background-color: #1c9ac0;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.buttonfisier {
  background-color: #0ea58c;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

</style>
