<template>
	<!-- Related projects start -->
	<div class="mt-10 sm:mt-20">
		<p
			class="text-primary-dark dark:text-primary-light text-3xl font-bold mb-10 sm:mb-14"
		>
			Related Projects
		</p>
		<div class="grid grid-cols-1 sm:grid-cols-4 gap-10">
			<div>
				<img
					src="../../assets/images/mobile-project-1.jpg"
					class="rounded-2xl cursor-pointer"
					alt="Related Project"
					loading="lazy"
				/>
			</div>
			<div>
				<img
					src="../../assets/images/web-project-1.jpg"
					class="rounded-2xl cursor-pointer"
					alt="Related Project"
					loading="lazy"
				/>
			</div>
			<div>
				<img
					src="../../assets/images/ui-project-2.jpg"
					class="rounded-2xl cursor-pointer"
					alt="Related Project"
					loading="lazy"
				/>
			</div>
			<div>
				<img
					src="../../assets/images/mobile-project-1.jpg"
					class="rounded-2xl cursor-pointer"
					alt="Related Project"
				/>
			</div>
		</div>
	</div>
	<!-- Related projects end -->
</template>

<script>
export default {
	setup() {},
};
</script>
