<template>
  <div class="one-user">
    <h1>this user</h1>
    <h2>{{name}}</h2>
    <input type="text" v-model="name">

    <button @click="check">Check</button>

  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
   data () {
    return {
      name: '',
      surname: '',
      perm: '',
      csrf: '',
      id: 3,
    }
    // this.$route.params.id
  },
  mounted () {
    axios.create({withCredentials: true}).get('http://localhost:4331/getcsrf')
      .then(res => {
        const data = res.data
        this.csrfToken = data.csrfToken
		console.log('this.csrfToken: ',this.csrfToken)
        axios.defaults.headers.common['XSRF-TOKEN'] = this.csrfToken
      })
      .catch(error => console.log(error))
  },
  methods: {
    check () {
      const formData = {
        name: this.name
      }
      axios.create({withCredentials: true}).post('http://localhost:4331/verifycsrf', formData)
        .then(response => {
          console.log('response: ',response)
        })
        .catch(error => console.log(error))
    },
    mySubmitEd () {
      const formData = {
        _id: this.id,
        name: this.name,
        last_name: this.surname,
        permalink: this.perm,
        _csrf: this.csrf
      }
      console.log(formData._csrf)
      axios.create({withCredentials: true}).post('http://localhost:4001/api/update-user', formData)
        .catch(error => console.log(error))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
