<template>


<div class="container4">
  <p class="mb-4 text-ternary-dark dark:text-ternary-light text-lg z-index=1">Pagina inca nu a fost creata. Va rugam sa folositi bara de cautare din fiecare pagina sau de pe pagina principala</p>
</div>

</template>


<script>
export default {
  name: 'PageNotFound',
  // created() {
  //   window.location.href = "/404/"
  // }
}
</script>

<style>
div.container4 {
    height: 10em;
    position: relative;
    z-index: -1;
    }
div.container4 p {
    margin: 0;
    background: none;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%) }
</style>