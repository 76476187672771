<template>
	<div id="app">
     <div class="folders">
		 <h3>Directoare: (Dati click pe numele directorului)</h3>
          <ul>
               <li v-for="(folder,index) in folders" :key="index">
                    <!--  Director nr. {{index}} {{folder.name}} -->
						<a @click="showFiles(folder.id)">
						<button class="button">Director nr. {{index}} {{folder.name}}</button> 
						</a>
               </li>
			   
          </ul>
     </div>

	 <h3>Fisierele din folderul: {{foldername}} (Dati click pe link-ul fisierului)</h3>
     <div class="files">
          <ul>
               <li v-for="(file,index) in files" :key="index">
                    <!-- Nume fisier: {{file}} : Link fisier ->  -->
					<a :href="/foldere/+foldername+'/'+file" target="_blank">
						<button class="buttonfisier">{{file}}</button> 
					</a>
               </li>
          </ul>
     </div>
</div>
</template>

<script>
import feather from 'feather-icons';

export default {
	name: 'FisiereFoldere',
	props: {
		msg: String,
	},
	data() {
		return {
			folders : [
        			{id: 0, name: 'folder1', files:['@favicon.png','cv.pdf','arhiva.zip']},
        			{id: 1, name: 'folder2', files:['@favicon.png2','cv2.pdf','arhiva2.zip']},
        			{id: 2, name: 'folder3', files:['@favicon.png3','cv3.pdf','arhiva3.zip']},
    			],
				files: [],
				foldername: ''
		}
		
	},
	methods: {
		/* eslint-disable no-mixed-spaces-and-tabs */
		//
		showFiles(id){
               let f = (this.folders).filter(f=>{return f.id==id})[0];
               this.files = f.files;
			   this.foldername = this.folders[id].name
			   console.log('foldername: ',this.folders[id].name)
          }
	},
		//return articole,
	mounted() {
		feather.replace();
	
		//props.articole.trim()
	},
	updated() {
		feather.replace();
		//datearticol() {
				/* eslint-disable no-mixed-spaces-and-tabs */
        		// manjson.sort( function (a, b) {
  				// 	return a.nr - b.nr;
				// });
      	//};
	},
	computed: {
		//manjson.sort((a, b) => a.name - b.name );
		//return manjson;
			// sortedArray() {
			// 	const sortDirection = 'DESC'
			// 	manjson.sort( function( a, b ){
    		// 		if( sortDirection == 'ASC' ){
      		// 			return parseInt( a.cafes_count ) < parseInt( b.cafes_count ) ? 1 : -1;
    		// 		}
    		// 		if( sortDirection == 'DESC' ){
      		// 			return parseInt( a.cafes_count ) > parseInt( b.cafes_count ) ? 1 : -1;
    		// 		}
			// 		return articole = manjson;
			// 	}
			// }
	}
	// computed() {
  	// 	sortedArray() {
    // 		function compare(a, b) {
    //   			if (a.name < b.name)
    //     			return -1;
    //   			if (a.name > b.name)
    //     			return 1;
    //   			return 0;	
    // 			}
    // 		return this.arrays.sort(compare);
  	// 	}
	// };
};
</script>

<style scoped>

.folders {
    float: left;
    width: 50%;
}

.files {
    margin-left: 50%;
}

.button {
  background-color: #1c9ac0;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.buttonfisier {
  background-color: #0ea58c;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

</style>
