<template>
	 <div class="block sm:flex gap-0 sm:gap-10 mt-14">
		<div class="w-full sm:w-1/3 text-left">
		</div>
		<div class="w-full sm:w-2/3 text-left mt-10 sm:mt-0">
		<p
				class="text-primary-dark dark:text-primary-light text-2xl font-bold mb-7"
			>
				{{datearticol[0].titlu}}
		</p>
		<p class="text-primary-dark dark:text-primary-light text-2xl font-bold mb-7" v-html="datearticol[0].continut">	
		</p>
		<br>	<div v-show="datearticol[0].attachment">
			Fisier atașat: {{datearticol[0].attachment.split('/')[4]}}
				<a
					:href="datearticol[0].attachment"
					class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-xl bg-indigo-50 hover:bg-indigo-500 text-gray-500 hover:text-white"
					target="_blank"
				>
					<i
						data-feather="arrow-down-circle"
						class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6"
					></i>
					<span class="text-sm sm:text-lg" v-if="datearticol[0].attachment != null"> Descarca  {{datearticol[0].attachment.split('/')[4].substring(0,17)}}...</span>
				</a>
				</div>
				<br>	<div v-show="datearticol[0].attachment2">
					Fisier atașat: {{datearticol[0].attachment2.split('/')[4]}}
				<a  
					:href="datearticol[0].attachment2"
					class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-xl bg-indigo-50 hover:bg-indigo-500 text-gray-500 hover:text-white"
					target="_blank"
					v-if="datearticol[0].attachment2"
				>
					<i
						data-feather="arrow-down-circle"
						class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6"
					></i>
					<span class="text-sm sm:text-lg" v-if="datearticol[0].attachment2 != null">Descarca {{datearticol[0].attachment2.split('/')[4].substring(0,17)}}...</span>
				</a>
				</div>
				<br>	
				<div v-show="datearticol[0].attachment3">
				<br>
				Fisier atașat: {{datearticol[0].attachment3.split('/')[4]}}
				<a
					:href="datearticol[0].attachment3"
					class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-xl bg-indigo-50 hover:bg-indigo-500 text-gray-500 hover:text-white"
					target="_blank"
					v-if="datearticol[0].attachment3"
				>				
					<i
						data-feather="arrow-down-circle"
						class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6"
					></i>
					<span class="text-sm sm:text-lg" v-if="datearticol[0].attachment3 != null">Descarca {{datearticol[0].attachment3.split('/')[4].substring(0,17)}}...</span>
				</a>
				</div>
				<br>	<div v-show="datearticol[0].attachment4">
					Fisier atașat: {{datearticol[0].attachment4.split('/')[4]}}
				<a
					:href="datearticol[0].attachment4"
					class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-xl bg-indigo-50 hover:bg-indigo-500 text-gray-500 hover:text-white"
					target="_blank"
					v-if="datearticol[0].attachment4"
				>
					<i
						data-feather="arrow-down-circle"
						class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6"
					></i>
					<span class="text-sm sm:text-lg" v-if="datearticol[0].attachment4 != null">Descarca {{datearticol[0].attachment4.split('/')[4].substring(0,17)}}...</span>
				</a>
				</div>
		<br>
		<p class="text-primary-dark dark:text-primary-light text-2xl font-bold mb-7">
			Data: {{datearticol[0].data}}
		</p>
		</div>
	</div>
</template>

<script>
import concjson from '../../components/baza/json/concursuri.json'
// import auxjson from '../../components/baza/json/manuale.json'
// import exajson from '../../components/baza/json/manuale.json'

export default {
	// setup() {
	// },
	data() {
		return {
			articole: concjson,	
			}
		},
		computed: {
			/* eslint-disable no-mixed-spaces-and-tabs */
      		datearticol() {
				/* eslint-disable no-mixed-spaces-and-tabs */
				//console.log('manjson:',manjson)
				/* eslint-disable no-mixed-spaces-and-tabs */
        		return concjson.filter( manual => manual.nr == this.$route.params.nr)
				//console.log('manjson:',manjson)
      		}
    }
	// 	mounted() {
	// 	const data = manjson.filter( manual => manual.nr == this.$route.params.nr)
	// 	//props.articole.trim()
	// },
};
</script>
