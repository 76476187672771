<template>
	<!-- Projects start -->
	<section class="pt-20 sm:pt-28">
		<div class="text-center">
			<p
				class="text-2xl sm:text-5xl font-semibold mb-3 text-ternary-dark dark:text-ternary-light"
			>
				Articole Directia Curriculum, Evaluari si Examene : {{count}}
			</p>
			<p class="text-md sm:text-xl text-gray-500 dark:text-ternary-light">
				Articolele sunt postate in ordine cronologica
			</p>
		 	
		<br>
			<p class="text-md sm:text-xl text-blue-500 dark:text-ternary-light">
				Cauta articol dupa titlu:
			</p>
		<input type="text" name="cautare" @onkeyup="resultQuery" v-model="search"/>   
			<div class="row profile" v-for="art in filteredList" :key="art.nr">
			
 			<!-- <div class="col-md-offset-1 col-md-3"><img :src="art.profile_pic" class="profile-pic" /></div> -->
 			<!-- <div v-if="search" class="col-md-8"><h4 class="name">{{art.titlu}}</h4></div> -->
			</div>
			<!-- <div v-if="filteredList.length"> Articole gasite: {{art.length}} </div> -->
		<p class="text-md sm:text-xl text-blue-500 dark:text-ternary-light" v-show="search">Articole gasite: {{filteredList.length}}</p>
		<button v-show="search" @click='reseteaza'
				class=" items-center px-6 py-3 rounded-xl shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 text-white text-lg sm:text-xl font-medium"
			>
				<i data-feather="refresh-cw" class="w-5 mr-3"></i>
				<span>Reseteaza...</span>
			</button>
		</div>

		<div class="grid grid-cols-1 sm:grid-cols-3 mt-16 sm:gap-10" >
			<router-link
			v-for="item in filteredList" :key="item.nr" 
			:to="item.to + item.nr"
			:class="item.class"
			>
			<!-- <div>
				<img :src="item.src" :alt="item.alt" class="rounded-t-3xl border-none" />
			</div> -->
			<div>
				<!-- src: {{ item.src }} alt: {{ item.alt }} to: {{ item.to }} -->
				<!-- {{item.src}} 
				<img :src="item.src" :alt="item.alt" :class="item.class1" /> 
				-->
				<!-- <img src="../../assets/images/manuale/toate780x680.png" :alt="item.alt" class="rounded-t-3xl border-none" /> -->
				<!-- {{ item.src }} = {{JSON.stringify(item.src)}} -->
				<br>
				<img :src="item.src" :alt="item.alt" class="rounded-t-3xl border-none" loading="lazy" />
				<!-- <img
						src="../../assets/images/web-project-2.jpg"
						alt=""
						class="rounded-t-3xl border-none"
					/> -->
			</div>
			<div class="text-center px-4 py-6">
					<p
						class="text-2xl text-ternary-dark dark:text-ternary-light font-semibold mb-2"
					>
						Articol {{item.titlu}}
					</p>
					<span
						class="text-lg text-ternary-dark dark:text-ternary-light"
						>{{item.titlu}}</span
					>
				</div>
			</router-link>

			<!-- <router-link
				to="/projects/single-project"
				class="rounded-3xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
			>
				<div>
					<img
						src="../../assets/images/web-project-2.jpg"
						alt=""
						class="rounded-t-3xl border-none"
					/>
				</div>
				<div class="text-center px-4 py-6">
					<p
						class="text-2xl text-ternary-dark dark:text-ternary-light font-semibold mb-2"
					>
						Google Health Platform
					</p>
					<span
						class="text-lg text-ternary-dark dark:text-ternary-light"
						>Web Application</span
					>
				</div>
			</router-link>
			<router-link
				to="/projects/single-project"
				class="rounded-3xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
			>
				<div>
					<img
						src="../../assets/images/mobile-project-2.jpg"
						alt=""
						class="rounded-t-3xl border-none"
					/>
				</div>
				<div class="text-center px-4 py-6">
					<p
						class="text-2xl text-ternary-dark dark:text-ternary-light font-semibold mb-2"
					>
						Pheonix Digital Agenncy
					</p>
					<span
						class="text-lg text-ternary-dark dark:text-ternary-light"
						>Mobile Application</span
					>
				</div>
			</router-link>
			<router-link
				to="/projects/single-project"
				class="rounded-3xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
			>
				<div>
					<img
						src="../../assets/images/ui-project-1.jpg"
						alt=""
						class="rounded-t-3xl border-none"
					/>
				</div>
				<div class="text-center px-4 py-6">
					<p
						class="text-2xl text-ternary-dark dark:text-ternary-light font-semibold mb-2"
					>
						Project Management UI
					</p>
					<span
						class="text-lg text-ternary-dark dark:text-ternary-light"
						>UI / Frontend</span
					>
				</div>
			</router-link>
			<router-link
				to="/projects/single-project"
				class="rounded-3xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
			>
				<div>
					<img
						src="../../assets/images/ui-project-2.jpg"
						alt=""
						class="rounded-t-3xl border-none"
					/>
				</div>
				<div class="text-center px-4 py-6">
					<p
						class="text-2xl text-ternary-dark dark:text-ternary-light font-semibold mb-2"
					>
						Cloud Storage Platform
					</p>
					<span
						class="text-lg text-ternary-dark dark:text-ternary-light"
						>UI / Frontend</span
					>
				</div>
			</router-link>
			<router-link
				to="/projects/single-project"
				class="rounded-3xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
			>
				<div>
					<img
						src="../../assets/images/mobile-project-1.jpg"
						alt=""
						class="rounded-t-3xl border-none"
					/>
				</div>
				<div class="text-center px-4 py-6">
					<p
						class="text-2xl text-ternary-dark dark:text-ternary-light font-semibold mb-2"
					>
						Kabul Social App
					</p>
					<span
						class="text-lg text-ternary-dark dark:text-ternary-light"
						>Mobile Application</span
					>
				</div>
			</router-link>
			<router-link
				to="/projects/single-project"
				class="rounded-3xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
			>
				<div>
					<img
						src="../../assets/images/web-project-1.jpg"
						alt=""
						class="rounded-t-3xl border-none"
					/>
				</div>
				<div class="text-center px-4 py-6">
					<p
						class="text-2xl text-ternary-dark dark:text-ternary-light font-semibold mb-2"
					>
						Apple Design System
					</p>
					<span
						class="text-lg text-ternary-dark dark:text-ternary-light"
						>Web Application</span
					>
				</div>
			</router-link> -->
		</div>
		<div class="mt-10 sm:mt-20 flex justify-center">
			<button
				class="flex items-center px-6 py-3 rounded-xl shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 text-white text-lg sm:text-xl font-medium"
			>
				<i data-feather="refresh-cw" class="w-5 mr-3"></i>
				<span>Incarca mai multe...</span>
			</button>
		</div>
	</section>
	<!-- Projects end -->
</template>

<script>
import feather from 'feather-icons';
//import articole from 'articole.json'
import manjson from '../../components/baza/json/manuale.json'
import auxjson from '../../components/baza/json/auxiliare.json'
import exajson from '../../components/baza/json/examene.json'
import currjson from '../../components/baza/json/curriculum.json'
//const images = require('/assets/images/auxiliare/')

export default {
	name: 'DceeGrid',
	props: {
		msg: String,
	},
	data() {
		return {
			articole: [...manjson, ...auxjson, ...exajson, ...currjson].reverse(),
			nums: [10,5,6,20,87],
			count: 0,
			search: '',
			
		}
		
	},
	methods: {
		/* eslint-disable no-mixed-spaces-and-tabs */
		// sortArt() {
		// 	manjson.sort( function (a, b) {
  		// 	 	return a.nr - b.nr;
		// 	});
		// 	console.log(manjson)
		// };
		/* eslint-disable no-mixed-spaces-and-tabs */
		countart() {
      	// `this` will refer to the component instance
      	this.count = this.articole.length
    	},
		sortArticole() {
			this.articole.sort( function(a,b) {
				return b.nr - a.nr
			})
		},
		reseteaza() {
			// this.search = null
			// return this.articole.filter(post => {
        	// 	return post.titlu.toLowerCase().includes(this.search.toLowerCase())
				
      		// })	
			//this.$forceUpdate();
			//this.$router.go('/')
			this.search = ''
			return this.articole = [...manjson, ...auxjson, ...exajson, ...currjson]
		},
	},
		//return articole,
	mounted() {
		feather.replace();
		this.countart();
		this.sortArticole();
		
		//props.articole.trim()
	},
	updated() {
		feather.replace();
		//datearticol() {
				/* eslint-disable no-mixed-spaces-and-tabs */
        		// manjson.sort( function (a, b) {
  				// 	return a.nr - b.nr;
				// });
      	//};
	},
	computed: {
		//manjson.sort((a, b) => a.name - b.name );
		//return manjson;
			// sortedArray() {
			// 	const sortDirection = 'DESC'
			// 	manjson.sort( function( a, b ){
    		// 		if( sortDirection == 'ASC' ){
      		// 			return parseInt( a.cafes_count ) < parseInt( b.cafes_count ) ? 1 : -1;
    		// 		}
    		// 		if( sortDirection == 'DESC' ){
      		// 			return parseInt( a.cafes_count ) > parseInt( b.cafes_count ) ? 1 : -1;
    		// 		}
			// 		return articole = manjson;
			// 	}
			// }
			filteredList() {
      		return this.articole.filter(post => {
        		return post.titlu.toLowerCase().includes(this.search.toLowerCase())
				
      		})	
    		},
	}
	// computed() {
  	// 	sortedArray() {
    // 		function compare(a, b) {
    //   			if (a.name < b.name)
    //     			return -1;
    //   			if (a.name > b.name)
    //     			return 1;
    //   			return 0;	
    // 			}
    // 		return this.arrays.sort(compare);
  	// 	}
	// };
};
</script>

<style scoped></style>
