<template>
	<div class="container mx-auto sm:flex py-5 sm:py-10 mt-10 sm:mt-20">
		<div class="w-full sm:w-1/2">
			<div class="leading-loose">
				<form
					class="max-w-xl m-4 p-6 sm:p-10 bg-secondary-light dark:bg-secondary-dark rounded-xl shadow-xl text-left"
				>
					<p
						class="text-primary-dark dark:text-primary-light text-2xl font-semibold mb-8"
					>
						Inscrie-te la newsletter
					</p>
					<div class="">
						<label
							class="block text-lg text-primary-dark dark:text-primary-light mb-2"
							for="name"
							>Nume si prenume</label
						>
						<input
							class="w-full px-5 py-2 border-0 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md dark:font-medium"
							id="name"
							name="name"
							v-model="numesiprenume"
							type="text"
							required=""
							placeholder="Nume si prenume - minim 7 caractere"
							aria-label="Name"
							minlength="7"
						/>
					</div>
					<div class="mt-6">
						<label
							class="block text-lg text-primary-dark dark:text-primary-light mb-2"
							for="email"
							>Email</label
						>
						<input
							class="w-full px-5 py-2 border-0 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md dark:font-medium"
							id="email"
							name="email"
							v-model="email"
							type="text"
							required=""
							placeholder="Email-ul tau"
							aria-label="Email"
						/>
						<div v-if="reg.test(email.toLowerCase()) !== true && email.length"
              				show
              				variant="warning" role="alert">
							<div class="bg-red-500 text-white font-bold rounded-t px-4 py-2">
								Atentie!!!
							</div>
  							<div class="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
              					<p>Email-incorect!!</p>
						</div>
						</div>
					</div>
					

					<div class="inputclass">
              			<label>Codul de siguranta:
                		<br />
                		<h5><strong>{{ codsig }}</strong></h5>
              			</label>
              			<br />
              			<p>Introdu codul de siguranta pentru a trimite mesajul!</p>
              		<input
            			type="number"
                		class="form-control"
                		placeholder="Introduce codul AICI"
                		autocomplete="codsigverif"
                		v-model="codsigverif"
              		/>
            		</div>
					 <input type="hidden" name="_csrf" v-model="csrfToken">
					<div v-show="codsig == codsigverif" class="mt-6">
						<button
							class="px-4 py-2.5 text-white font-medium tracking-wider bg-indigo-500 hover:bg-indigo-600 rounded-lg"
							type="submit"
							@click.prevent="subscribeemail"
						>
							Subscribe
						</button>
					</div>
					<input  type="hidden" name="_csrf" v-bind:value="csrfToken">
					<!-- token: {{csrfToken}}
					<div v-show="codsig == codsigverif" class="mt-6">
						<button
							class="px-4 py-2.5 text-white font-medium tracking-wider bg-indigo-500 hover:bg-indigo-600 rounded-lg"
							type="submit"
							@click.prevent="testcsrf"
						>
							Test csrf
						</button>
					</div> -->
				</form>
			</div>
		</div>
		<div class="w-full sm:w-1/2">
			<div class="text-left max-w-xl px-6">
				<h2
					class="text-2xl text-primary-dark dark:text-primary-light font-semibold mt-12 mb-8"
				>
					Detalii de contact:
				</h2>
				<ul class="">
					<li class="flex">
						<i
							data-feather="map-pin"
							class="w-5 text-gray-500 dark:text-gray-400 mr-4"
						></i>
						<p
							class="text-lg mb-4 text-ternary-dark dark:text-ternary-light"
						>
							Str. General Berthelot nr. 26, Sector 1, 010168, Bucureşti
						</p>
					</li>
					<li class="flex">
						<i
							data-feather="mail"
							class="w-5 text-gray-500 dark:text-gray-400 mr-4"
						></i>
						<p
							class="text-lg mb-4 text-ternary-dark dark:text-ternary-light"
						>
							office@rocnee.eu
						</p>
					</li>
					<li class="flex">
						<i
							data-feather="phone"
							class="w-5 text-gray-500 dark:text-gray-400 mr-4"
						></i>
						<p
							class="text-lg mb-4 text-ternary-dark dark:text-ternary-light"
						>
							Tel: +40.21.314.44.11, Fax: +40.21.310.32.07
						</p>
					</li>
					
					<li class="flex">
						<i
							data-feather="map-pin"
							class="w-5 text-gray-500 dark:text-gray-400 mr-4"
						></i>
						<div class="mapouter"><div class="gmap_canvas"><iframe class="gmap_iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=474&amp;height=262&amp;hl=en&amp;q=Str. General Berthelot, nr. 26, Bucharest&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href="https://www.fridaynightfunkin.net/friday-night-funkin-mods-fnf-play-online/">Friday Night Funkin Mods</a></div></div>
					</li>
					
					<li class="flex">
						<i
							data-feather="map-pin"
							class="w-5 text-gray-500 dark:text-gray-400 mr-4"
						></i>
						<p
							class="text-lg mb-4 text-ternary-dark dark:text-ternary-light"
						>
							Directii de orientare:
						</p>
						<div>
							<a href="https://www.google.com/maps/dir//Strada+General+H.+M.+Berthelot+26,+Bucure%C8%99ti+010164,+Romania/@44.441842,26.0871683,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x40b1ff5a7d97c4ff:0x159af629dd24d226!2m2!1d26.089357!2d44.441842?hl=en">Click aici</a>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import feather from 'feather-icons';
import axios from "axios";

export default {
	name: "Contact",
  props: {
    csrfname: {
      type: String,
        default: null
    } 
  },
	data: () => {
		return {
			csrfToken: null,
			numesiprenume: "",
			email: "",
			subiect: "",
			mesaj: "",
			codsigverif: 0,
			codsig: 0,
			reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		};
	},

	beforeMount() {
		//this.getcsrf()
		this.getcsrfToken()
	},

	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
	created: function() {
    	this.getRandomNumber();
  	},
	methods: {
		subscribeemail() {
			console.log("numesiprenume: ",this.numesiprenume)
			const formData = {
				numesiprenume: this.numesiprenume,
				email: this.email	
			}
			axios.create({withCredentials: true}).post('http://localhost:4331/api/subscribe', formData)
				.then(response => {
					console.log('response: ',response)
        			const data = response.data
        			console.log('data: ',data)

      			})
      			.catch(error => console.log(error))

		},

		getcsrfToken() {
			axios.create({withCredentials: true}).get('http://localhost:4331/getcsrf')
      			.then(res => {
        			const data = res.data
        			this.csrfToken = data.csrfToken
        			axios.defaults.headers.common['XSRF-TOKEN'] = this.csrfToken
      			})
      			.catch(error => console.log(error))
		},

		
		getRandomNumber: function() {
      		this.codsig = this.generateNumber();
    	},
    	generateNumber: function() {
      		return Math.floor(Math.random() * (999999999 - 10000000 + 1) + 10000000);
    	},
		async trimitemesaj() {
			//console.log("date de trimis: ",this.numesiprenume + ' ' + this.email + ' ' + this.subiect + ' ' + this.mesaj)
			console.log('this.subiect.length: ',this.subiect.length + " : " + this.numesiprenume.length + " : " +  this.mesaj.length)
			if(!this.numesiprenume || !this.email || !this.subiect || !this.mesaj) {
				alert('Trebuie sa completati toate campurile!');
				return 1
			}
			if(this.numesiprenume.length < 7 || this.subiect.length < 5 || this.mesaj.length < 32) {
				alert('Trebuie sa respectati cerintele minime de completare trecute in fiecare camp!!!');
				return 2
			}
			//let email = this.email;
      		const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      		// if (this.email == null || this.email == "") {
        	// 		let errorEmail = "Introduceti email-ul!";
			// 		alert('error: ',errorEmail)
      		// 	} else if (!this.reg.test(this.email)) {
        	// 	let errorEmail = "Introduceti email-ul corect!";
			// 	alert('error: ',errorEmail)
      		// }
			await axios.create({withCredentials: true,  headers: {Accept: "application/json", "Content-Type": "application/json"}}).post("http://localhost:4331/api/sendmail", 
			 	{
				csrfToken: this.csrfToken,
				tokenapimail: "ke3-3=-53teujkgfs=2=gdsg,g,hkndsjdj2ADSAGLF =!!@e<f<}t^tyuh<fbnf:b", 
			 	subject: this.subiect, 
			 	email: this.email,
			 	message: this.mesaj,
			 	ExtIp: "localhost"
			 })
        	.then(response => {
          		let info = response;
          		//console.log("response: ", response);
          		if (response.data.accepted.length == 1) {
            		alert("Mesajul a fost trimis!");
            		//this.cangenpdf = true;
            		//this.$router.push("/savepdf/" + this.$route.params.id);
          			} else alert("Mesajul nu a fost trimis! S-a ivit o eroare:", response);
        		})
        		.catch(error => alert("Eroare: " + error + " !"));
			}
	},
};
</script>

<style scoped>
.mapouter{position:relative;text-align:right;width:474px;height:262px;}.gmap_canvas {overflow:hidden;background:none!important;width:474px;height:262px;}.gmap_iframe {width:474px!important;height:262px!important;}
</style>