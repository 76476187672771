<template>
	<!-- Project gallery start -->
	
	<div class="grid grid-cols-1 sm:grid-cols-3 sm:gap-10 mt-12">
		
		<div class="mb-10 sm:mb-0">
			<!-- //src="../../assets/images/manuale/manuale250.png" -->
			<img
				
				src="/public_images/programe/programe.png"
				class="rounded-2xl cursor-pointer shadow-lg sm:shadow-none"
				alt="articol-manuale"
				loading="lazy"
			/>
		</div>
		<!-- <div class="mb-10 sm:mb-0">
			<img
				src="../../assets/images/web-project-2.jpg"
				class="rounded-2xl cursor-pointer shadow-lg sm:shadow-none"
				alt="Kabul Project Management UI"
			/>
		</div>
		<div>
			<img
				src="../../assets/images/mobile-project-2.jpg"
				class="rounded-2xl cursor-pointer shadow-lg sm:shadow-none"
				alt="Kabul Project Management UI"
			/>
		</div> -->
	</div>
	<!-- Project gallery end -->
</template>

<script>
export default {
	setup() {},
};
</script>
