<template>
	<div class="container mx-auto">
		<!-- Projects start -->
		<VenituriangajatiGrid />
		<!-- Projects end -->
	</div>
</template>

<script>
import VenituriangajatiGrid from '@/components/project/VenituriangajatiGrid';
import feather from 'feather-icons';

export default {
	name: 'Venituriangajati',
	components: {
		VenituriangajatiGrid,
	},
	props: {
		msg: String,
	},
	data: () => {
		return {};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<style scoped></style>
