<template>
	<!-- Projects start -->
	<section class="pt-20 sm:pt-28">
		<div class="text-center">
			<p
				class="text-2xl sm:text-5xl font-semibold mb-3 text-ternary-dark dark:text-ternary-light"
			>
				Articole examene : {{count}}
			</p>
			<p class="text-md sm:text-xl text-gray-500 dark:text-ternary-light">
				Articolele sunt postate in ordine cronologica
			</p>

			<br>
			<p class="text-md sm:text-xl text-blue-500 dark:text-ternary-light">
				Cauta articol dupa titlu:
			</p>
		<input type="text" name="cautare" @onkeyup="resultQuery" v-model="search"/>   
			<div class="row profile" v-for="art in filteredList" :key="art.nr">
			
 			<!-- <div class="col-md-offset-1 col-md-3"><img :src="art.profile_pic" class="profile-pic" /></div> -->
 			<!-- <div v-if="search" class="col-md-8"><h4 class="name">{{art.titlu}}</h4></div> -->
			</div>
			<!-- <div v-if="filteredList.length"> Articole gasite: {{art.length}} </div> -->
		<p class="text-md sm:text-xl text-blue-500 dark:text-ternary-light" v-show="search">Articole gasite: {{filteredList.length}}</p>
		<button v-show="search" @click='reseteaza'
				class=" items-center px-6 py-3 rounded-xl shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 text-white text-lg sm:text-xl font-medium"
			>
				<i data-feather="refresh-cw" class="w-5 mr-3"></i>
				<span>Reseteaza...</span>
			</button>
		</div>

		

		<div class="grid grid-cols-1 sm:grid-cols-3 mt-16 sm:gap-10" >
			<router-link
			v-for="item in filteredList" :key="item.nr" 
			:to="item.to + item.nr"
			:class="item.class"
			>
			<div>
				<img src="../../assets/images/manuale/toate250.png" alt="item.alt" class="item.class1" loading="lazy"/>
			</div>
			<div class="text-center px-4 py-6">
					<p
						class="text-2xl text-ternary-dark dark:text-ternary-light font-semibold mb-2"
					>
						{{item.titlu}}
					</p>
					<!-- <span
						class="text-lg text-ternary-dark dark:text-ternary-light"
						> {{item.continut.slice(0, 73)}} ...</span
					> -->
				</div>

			<div  class="text-center px-4 py-4">
				Data: {{item.data}}
			</div> 

			</router-link>

		</div>
		<!-- <div class="mt-10 sm:mt-20 flex justify-center">
			<button
				class="flex items-center px-6 py-3 rounded-xl shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 text-white text-lg sm:text-xl font-medium"
			>
				<i data-feather="refresh-cw" class="w-5 mr-3"></i>
				<span>Incarca mai multe...</span>
			</button>
		</div> -->
	</section>
	<!-- Projects end -->
</template>

<script>
import feather from 'feather-icons';
//import articole from 'articole.json'
import exajson from '../../components/baza/json/examene.json'

export default {
	name: 'ExameneGrid',
	props: {
		msg: String,
	},
	data() {
		return {
			articole: exajson.reverse(),
			/* eslint-disable no-mixed-spaces-and-tabs */
			count: 0,
			search: '',
		}
		
		},
		computed: {

			resultQuery() {
			console.log('search: ',this.search)
      		if(this.search){
      			// return this.articole.filter((item)=>{
        		// 	return this.search.toLowerCase().split(' ').every(v => item.titlu.toLowerCase().includes(v))
      			// })
				return this.articole.filter( (item) => this.articole.titlu.toLowerCase().includes(this.search.toLowerCase()))
			} else {
        	return this.articole;
      		}
    	},

		filteredList() {
      		return this.articole.filter(post => {
        		return post.titlu.toLowerCase().includes(this.search.toLowerCase())
				
      		})	
    	},
		

		},
		
		methods: {
		/* eslint-disable no-mixed-spaces-and-tabs */
		// sortArt() {
		// 	manjson.sort( function (a, b) {
  		// 	 	return a.nr - b.nr;
		// 	});
		// 	console.log(manjson)
		// };
		/* eslint-disable no-mixed-spaces-and-tabs */
		nrarticole() {
      	// `this` will refer to the component instance
      		this.count = this.articole.exajson
    	},

		reseteaza() {
			// this.search = null
			// return this.articole.filter(post => {
        	// 	return post.titlu.toLowerCase().includes(this.search.toLowerCase())
				
      		// })	
			//this.$forceUpdate();
			//this.$router.go('/')
			this.search = ''
			return this.articole = exajson
		},
		// resultQuery() {
		// 	console.log('search: ',this.search)
      	// 	if(this.search){
      	// 		// return this.articole.filter((item)=>{
        // 		// 	return this.search.toLowerCase().split(' ').every(v => item.titlu.toLowerCase().includes(v))
      	// 		// })
		// 		return this.articole.filter( (item) => this.articole.toLowerCase().includes(this.search))
		// 	} else {
        // 	return this.articole;
      	// }
    	// },

		sortArticole() {
			if(!this.search) {
				this.articole.sort( function(a,b) {
				return b.nr - a.nr
				})
			} else {
				return this.articole.titlu.toLowerCase().includes(this.search)
				//return this.articole.filter( function(art) {
					//return art.titlu.toLowerCase().includes(this.search)
			//})
			}
			
		}
		},
		//return articole,
	mounted() {
		feather.replace();
		this.nrarticole();
		this.sortArticole();
	},
	updated() {
		feather.replace();
	},
};
</script>

<style scoped></style>
