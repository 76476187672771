<template>
	<div class="container mx-auto">
		<!-- Projects start -->
		<FisiereFoldere />
		<!-- Projects end -->
	</div>
</template>

<script>
import FisiereFoldere from '@/components/project/FisiereFoldere';
import feather from 'feather-icons';

export default {
	name: 'Auxiliare',
	components: {
		FisiereFoldere,
	},
	props: {
		msg: String,
	},
	data: () => {
		return {};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<style scoped></style>
