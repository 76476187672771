<template>
	<!-- Project information start -->
	<div class="block sm:flex gap-0 sm:gap-10 mt-14">
		<div class="w-full sm:w-1/3 text-left">
			<!-- <div class="mb-7">
				<p
					class="text-2xl font-semibold text-secondary-dark dark:text-secondary-light mb-2"
				>
					About Client
				</p>
				<ul class="leading-loose">
					<li class="text-ternary-dark dark:text-ternary-light">
						<span>Name: </span>
						Alin Company Ltd
					</li>
					<li class="text-ternary-dark dark:text-ternary-light">
						<span>Services: </span>
						UI Design & Frontend Development
					</li>
					<li class="text-ternary-dark dark:text-ternary-light">
						<span>Website: </span>
						<a href="#">https://alindebian.me</a>
					</li>
					<li class="text-ternary-dark dark:text-ternary-light">
						<span>Phone: </span>
						+40 771 140 432
					</li>
				</ul>
			</div> -->
			<!-- <div class="mb-7">
				<p
					class="text-2xl font-semibold text-ternary-dark dark:text-ternary-light mb-2"
				>
					Objective
				</p>
				<p class="text-primary-dark dark:text-ternary-light">
					Lorem ipsum dolor sit amet consectetur adipisicing elit.
					Optio, natus! Quibusdam enim quod in esse, mollitia
					molestias incidunt quas ipsa accusamus veniam, quis odit
					cumque vero voluptate, reiciendis amet non!
				</p>
			</div> -->
			<!-- <div class="mb-7">
				<p
					class="text-2xl font-semibold text-ternary-dark dark:text-ternary-light mb-2"
				>
					Tools & Technologies
				</p>
				<p class="text-primary-dark dark:text-ternary-light">
					HTML, CSS, JavaScript, Vue.js, TailwindCSS, AdobeXD
				</p>
			</div> -->
			<!-- <div>
				<p
					class="text-2xl font-semibold text-ternary-dark dark:text-ternary-light mb-2"
				>
					Share This
				</p>
				<div class="flex items-center gap-3 mt-5">
					<a
						href="https://twitter.com/NangialaiAlindebian"
						target="__blank"
						class="bg-ternary-light dark:bg-ternary-dark text-gray-400 hover:text-primary-dark dark:hover:text-primary-light p-2 rounded-lg shadow-sm"
						><i data-feather="twitter" class="w-5 h-5"></i
					></a>
					<a
						href="https://instagram.com/NangialaiAlindebian"
						target="__blank"
						class="bg-ternary-light dark:bg-ternary-dark text-gray-400 hover:text-primary-dark dark:hover:text-primary-light p-2 rounded-lg shadow-sm"
						><i data-feather="instagram" class="w-5 h-5"></i
					></a>
					<a
						href="https://facebook.com/nangialai.Alindebian"
						target="__blank"
						class="bg-ternary-light dark:bg-ternary-dark text-gray-400 hover:text-primary-dark dark:hover:text-primary-light p-2 rounded-lg shadow-sm"
						><i data-feather="facebook" class="w-5 h-5"></i
					></a>

					<a
						href="https://linkedin.com/"
						target="__blank"
						class="bg-ternary-light dark:bg-ternary-dark text-gray-400 hover:text-primary-dark dark:hover:text-primary-light p-2 rounded-lg shadow-sm"
						><i data-feather="linkedin" class="w-5 h-5"></i
					></a>
					<a
						href="https://www.youtube.com/c/AlindebianStudio"
						target="__blank"
						class="bg-ternary-light dark:bg-ternary-dark text-gray-400 hover:text-primary-dark dark:hover:text-primary-light p-2 rounded-lg shadow-sm"
						><i data-feather="youtube" class="w-5 h-5"></i
					></a>
				</div>
			</div> -->
		</div>
		<div class="w-full sm:w-2/3 text-left mt-10 sm:mt-0" v-for="item in articole" :key="item.nr">
			<p
				class="text-primary-dark dark:text-primary-light text-2xl font-bold mb-7"
			>
				Challenge
			</p>
			<p class="mb-5 text-lg text-ternary-dark dark:text-ternary-light">
				{{ item.titlu }}
			</p>
			<p class="mb-5 text-lg text-ternary-dark dark:text-ternary-light">
				{{item.continut}}
			</p>
			<!-- <p class="mb-5 text-lg text-ternary-dark dark:text-ternary-light">
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
				ipsa, ullam modi esse ea molestiae, non quidem expedita
				perspiciatis nihil eligendi maxime eum eos exercitationem.
				Aliquam labore amet odio quisquam? Lorem ipsum dolor sit amet
				consectetur, adipisicing elit. Velit, consectetur? Soluta,
				minus, quia tenetur distinctio asperiores aperiam impedit
				provident at autem omnis odio, atque dicta minima alias
				voluptatum ullam animi.
			</p>
			<p class="mb-5 text-lg text-ternary-dark dark:text-ternary-light">
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
				ipsa, ullam modi esse ea molestiae, non quidem expedita
				perspiciatis nihil eligendi maxime eum eos exercitationem.
				Aliquam labore amet odio quisquam?
			</p> -->
		</div>
	</div>
	<!-- Project information end -->
</template>

<script>
export default {
	setup() {},
};
</script>
